<template>
    <v-app-bar absolute color="transparent" elevation="0">
    <v-app-bar-nav-icon @click="$router.go(-1)" v-if="backward" data-cy="CameraNavBar-backward-btn">
       <template slot="default">
           <v-btn icon color="natural" ><v-icon>mdi-arrow-left</v-icon></v-btn>
       </template>
     
         </v-app-bar-nav-icon>
         <v-spacer/>
          <v-toolbar-title>
   <div class="text-title-1 natural--text">{{title}}</div>
    </v-toolbar-title>
  
     <!-- <v-app-bar-title class="text-title-1 white--text">{{title}}</v-app-bar-title> -->
        <v-spacer/>
<!-- <v-btn v-if="backward" icon></v-btn> -->


  <v-menu offset-y :rounded="'0'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon  v-bind="attrs" v-on="on"><v-icon color="natural">mdi-rotate-3d-variant</v-icon></v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in devices"
          :key="index"
          @click="onCameraChanged(item)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

 <!-- <template v-slot:extension>
      <v-spacer>    <div class="text-title-3 natural--text">{{title}}</div></v-spacer>
        </template> -->
     </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'หัวข้อแสดงผล H4' 
    },
    backward:{
      type: Boolean,
      default: false
    },
    devices: {
      type: Array
    }
  },
  async mounted () {
    // const tempStream = await navigator.mediaDevices.getUserMedia({video:true})
    // console.log('Ask permission' + tempStream)
    // navigator.mediaDevices.enumerateDevices().then(this.gotDevices).catch(this.handleError)
  },
  methods: {
    // gotDevices(deviceInfos) {
    //    for (let i = 0; i !== deviceInfos.length; ++i) {
    // const deviceInfo = deviceInfos[i];
    // if (deviceInfo.kind === 'videoinput') {
    //   this.devices.push({text: deviceInfo.label, value: deviceInfos[i].deviceId})
    //   console.log('Device Info source/device: ', deviceInfo);
    // } else {
    //   console.log('Some other kind of source/device: ', deviceInfo);
    // }
    // }
    // },
    // handleError(error)  {
    //     console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
    // },
    onCameraChanged(device){
      this.$emit("onCameraChanged", device.value)
    }
  }

}
</script>

<style>

</style>